/* Gallery.css */

/* Container Styling */
.gallery-container {
  padding: 20px;
  background-color: #1e1e1e; /* Dark background */
  color: #e0e0e0; /* Light text for contrast */
  min-height: 100vh;
}

/* Gallery Title */
.gallery-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #ffffff; /* Brighter text */
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.gallery-image-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  background-color: #2c2c2c; /* Slightly lighter than container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-image-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s;
}

.gallery-image-card:hover .gallery-image {
  transform: scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .gallery-title {
    font-size: 2.2rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .gallery-title {
    font-size: 2rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .gallery-title {
    font-size: 1.8rem;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
