/* src/components/ObjectRemoval.css */

.object-removal-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.object-removal-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #555;
}

.upload-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-label {
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.upload-label:hover {
  background-color: #e0e0e0;
}

.upload-label span {
  margin-right: 10px;
  font-weight: 500;
}

.upload-label input[type='file'] {
  display: none;
}

.brush-settings {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px;
}

.brush-settings label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.brush-settings input[type='color'],
.brush-settings input[type='range'] {
  margin-left: 10px;
}

.brush-actions button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.brush-actions button:disabled {
  background-color: #a0c4ff;
  cursor: not-allowed;
}

.brush-actions button:not(:disabled):hover {
  background-color: #0056b3;
}

.canvas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  background-color: #fafafa;
}

.canvas-stage {
  touch-action: none; /* Prevent default touch behaviors */
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.action-buttons button {
  padding: 12px 24px;
  background-color: #28a745;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.action-buttons button:disabled {
  background-color: #a8e6cf;
  cursor: not-allowed;
}

.action-buttons button:not(:disabled):hover {
  background-color: #218838;
}

.spinner {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #fff; /* White */
  border-right: 3px solid #fff; /* White */
  border-bottom: 3px solid #fff; /* White */
  border-left: 3px solid #fff; /* White */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
  color: #555;
}

.error-message {
  color: #dc3545; /* Bootstrap's danger color */
}

.result-section {
  text-align: center;
  margin-top: 20px;
}

.result-section h4 {
  margin-bottom: 10px;
  color: #555;
}

.result-image {
  max-width: 100%;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.download-button {
  padding: 10px 20px;
  background-color: #17a2b8;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.download-button:hover {
  background-color: #117a8b;
}

/* Responsive Design */
@media (max-width: 768px) {
  .brush-settings {
    flex-direction: column;
    align-items: flex-start;
  }

  .brush-actions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .action-buttons button {
    width: 100%;
  }

  .canvas-wrapper {
    width: 100%;
  }
}
