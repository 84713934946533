// Define primary and secondary colors
$primary-color: #28a745; // Primary color (e.g., for buttons, links)
$secondary-color: #007bff; // Secondary color (e.g., for hover states, background highlights)

// Additional variables
$text-color: #333;
$light-text-color: #6c757d;
$dark-background: #1e1e1e;
$light-background: #f9f9f9;
$hover-color: #0056b3;
$border-color: #ddd;
$image-border-color:#0056b3;
