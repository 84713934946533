@import './styles/variables.scss';

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(240 249 255);
  background-size: cover;
}

h1 {
  text-align: center;
  margin: 20px;
}

.home-container,
.set-page-container,
.result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: $text-color;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.preset-images {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0;
}

.preset-image {
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.preset-image.selected {
  border: 2px solid $secondary-color;
}

.result-image {
  max-width: 100%;
  border-radius: 10px;
}

.download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: $primary-color;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}
